import React from 'react';
import classNames from 'classnames';
import { fmtMsg } from '@app/util';
import './label-text.less';

export const LabelText = ({ labelId, text = null, hasColon = true, icon = null, children = null, labelIdParams = null, customClass = null }) => {
    const labelClassName = {
        'label-text-label': true,
        'label-text-lable-with-colon': hasColon,
        'label-text-label-with-text': text != null
    }
    const label = fmtMsg({ id: labelId }, {...labelIdParams});
    return (
        <div className='label-text'>
            <div className={classNames(labelClassName)}>
                <span>
                    {label}
                </span>
                {icon != null && icon }
            </div>          
            <div className={`label-text-text ${customClass}`}>
                {children ? 
                    children :
                    (
                    <span>
                        {text == null ? '' : text}
                    </span>
                    )
                }
            </div>
        </div>
    )
}