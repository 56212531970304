import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ColumnProps } from "antd/lib/table";
import { RouteComponentProps } from 'react-router';
import { Icon, DatePicker } from 'antd-min';
import { withRouter, maskThrottle, unmaskThrottle, GLFormComponentProps, GLForm, FormHelper, LanguageDateFormat, alignPop } from 'gl-commonui';
import { useService } from "@app/hooks";
import { TYPES } from "@app/service/types";
import { GLUtil, GLGlobal } from 'gl-commonui';
import { PathConfig, SchoolPathConfig } from '@app/config/pathconfig';
import { DateHelper, fmtMsg, GSSchoolAction, GSAdminAction, stringCompare, TrainingCourseStatus, sliceMoment, ContextHelper } from '@app/util';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { setBreadcrumbs } from '@app/states/resource';
import { IVisitationService, SchoolCoachVisitation, SchoolVisitationHistory, SchoolVisitationHistoryPropsModel, SchoolCoachFeedback, SchoolCoachFeedbackModel, VisitationTeacher, SchoolVisitationHistories } from '@app/service/coach/visitation';
import { VisitationType, VisitationCoachStage, VisitationStage, SurveyTodoType } from '@app/util/coach/enum';
import { ITrainingService } from '@app/service/training';
import { LabelText } from '@app/page/school/visitation/component/label-text';
import { UserAvatar } from '../component/avatar';
import { Stage } from './stage';
import { HistoryTable } from '../component/history-table';
import { IconButton } from '../component/icon-button';
import './style.less';
import { FeedbackTable } from '../component/feedback-table';
import { ReviewFeedback } from '../component/review-feedback';
import { IUserService } from '@app/service/users';
import { CourseHistory } from '../teacher/component/course-history';
import { ReviewInfo } from '../teacher/component/review-info';
import { TeacherModal } from '@app/page/school/visitation/component/history-table';
import { IReportService } from '@app/service/school/report';
import { ColumnLink } from '@app/components';

interface SchoolCoachProps extends RouteComponentProps<any>, GLFormComponentProps {
    setBreadcrumbs?: (data) => void
}

export const SchoolCoach = (props: SchoolCoachProps) => {

    const [courseHistoryLoading, setCourseHistoryLoading] = useState(false);
    const [trainingPath, setTrainingPath] = useState(null);
    const [trainingPathChanged, setTrainingPathChanged] = useState(false);
    const [visitationUIStage, setVisitationUIStage] = useState(VisitationStage.OnGoing);
    const [coachVisitation, setCoachVisitation] = useState({} as SchoolCoachVisitation);
    const [feedbackDetail, setFeedbackDetail] = useState({} as SchoolCoachFeedback);
    const [surveyQuestion, setSurveyQuestion] = useState([]);
    const [reviewDetailVisible, setReviewDetailVisible] = useState(false);
    const [courseHistories, setCourseHistories] = useState([]);
    const [courseHistoryVisible, setCourseHistoryVisible] = useState(false);
    const visitationService = useService<IVisitationService>(TYPES.IVisitationService);
    const trainingService = useService<ITrainingService>(TYPES.ITrainingService);
    const reportService = useService<IReportService>(TYPES.IReportService);
    const userService = useService<IUserService>(TYPES.IUserService);
    const { schoolId, trainerId, regiontrainerId } = props.match.params;
    const { visitationId, teacherId } = GLUtil.queryParse();
    const coachId = trainerId || regiontrainerId || ContextHelper.getUserLoginInfo().profile.sub;
    const trainingPathField = 'trainingPath';
    const [visiblePopup, setVisiblePopup] = useState<boolean>(false);
    const [visitationTeachers, setVisitationTeachers] = useState<VisitationTeacher[]>([]);
    const setModalIsOpenToTrue = (listVisition: VisitationTeacher[]) => {
        setVisiblePopup(true);
        setVisitationTeachers(listVisition);
      };
    const setModalIsOpenToFalse =()=>{
        setVisiblePopup(false)
    }
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        hideOnSinglePage: true,
        pageSize: 20,
        onChange: (page) => onViewCourseHistory(page),
    });

    useEffect(() => {
        if (visitationId && teacherId) {
            getSpecifiedStage();
        } else {
            getVisitation(visitationUIStage);
        }

        const queryParams = GLUtil.queryParse();

        if (queryParams && queryParams.questionnaireId && queryParams.courseVersionId) {
            onViewCourseHistory();
        }
    }, []);

    const getSpecifiedStage = async () => {
        const stage = await visitationService.getVisitationStage({ visitationId });
        const visitationUIStage = formatVisitationUIStage(stage);
        setVisitationUIStage(visitationUIStage);
        getVisitation(visitationUIStage);
    }

    const getVisitation = (stage) => {
        maskThrottle();
        let promises = [];
        promises.push(visitationService.getCoachVisitation(coachId, getVisitationStage(stage), schoolId));
        promises.push(trainingService.getCourseTrainingInfo(coachId));
        promises.push(userService.getUserAvatarSasUri({ id: coachId, expirationminutes: 120 }));
        promises.push(reportService.getReviewedSummary(coachId));
        Promise.all(promises).then((result) => {
            const coachName = result[0].data.coachName;
            const visitations = result[0].data.visitations.sort((a, b) => -stringCompare(a.visitationResponseModel[SchoolVisitationHistoryPropsModel.startDate],
                b.visitationResponseModel[SchoolVisitationHistoryPropsModel.startDate], 'en'));
            const feedbacks = [];
            visitations.forEach(v => {
                v.reviewCoachInfoModels.forEach(r => {
                    feedbacks.push({ ...r, ...v.visitationResponseModel });
                });
            });
            const feedback = visitationId && teacherId &&
                feedbacks.find(f => f.id == visitationId && f.teacherId == teacherId);
            feedback && onViewFeedbackDetail(feedback);
            setCoachVisitation({
                coachId,
                coachName,
                coachAvatar: result[2],
                visitations,
                feedbacks,
                videoViewed: result[3]
            });
            formatTrainingPath(result[1].statuses);
            if (schoolId) {
                props.setBreadcrumbs({
                    trainer: {
                        id: coachId,
                        name: coachName
                    }
                });
            }
            else {
                props.setBreadcrumbs({
                    regionTrainer: {
                        id: coachId,
                        name: coachName
                    }
                });
            }
            unmaskThrottle();
        }).catch(er => {
            unmaskThrottle();
        });
    }

    const formatTrainingPath = (trainingStatus) => {
        const trainingPath = trainingStatus.find(s => s.courseTypeId == TrainingCourseStatus.TrainingPath && s.completionDate != null);
        setTrainingPath(trainingPath ? DateHelper.toLocalMoment(trainingPath.completionDate) : null);
    }

    const getVisitationStage = (stage: VisitationStage) => {
        switch (stage) {
            case VisitationStage.Pending:
                return VisitationCoachStage.ScheduleDate;
            case VisitationStage.Completed:
                return VisitationCoachStage.Completed;
            default:
                return null;
        }
    }

    const formatVisitationUIStage = (stage: VisitationCoachStage) => {
        switch (stage) {
            case VisitationCoachStage.ScheduleDate:
                return VisitationStage.Pending;
            case VisitationCoachStage.Completed:
                return VisitationStage.Completed;
            default:
                return VisitationStage.OnGoing;
        }
    }

    const formatHistories = (histories) => {
        if (!histories) {
            return histories;
        }
        return histories.map((history) => {
            const { visitationResponseModel: { schoolName, visitationTeacherHistories, campusName, type, startDate } } = history;
            return {
                ...history,
                schoolName,
                campusName,
                startDate,
                visitationTeacherHistoriesCount: visitationTeacherHistories ? visitationTeacherHistories.length : '',
                visitationTypeText: type === VisitationType.OnSite ? fmtMsg({ id: SchoolLocale.VisitationCoachVisitationText }) : fmtMsg({ id: SchoolLocale.VisitationLVAVisitationText }),
            }
        })
    }

    const getColumns = (): ColumnProps<SchoolVisitationHistory>[] => {
        let columns = [
            {
                title: fmtMsg({ id: SchoolLocale.SchoolVisitationHistorySchoolColumn }),
                dataIndex: SchoolVisitationHistoryPropsModel.schoolName,
                width: '25*',
                className: 'navigation-col',
                render: (text, history, index) => {
                    const { regionId } = props.match.params;
                    const { visitationResponseModel: { schoolId, schoolName } } = history;
                    const pathCampuses = regionId && schoolId ? GLUtil.pathStringify(PathConfig.Schools, { regionId, schoolId }) : null;
                    if (GLGlobal.isActionValid(GSSchoolAction.Campuses) && pathCampuses) {
                        return (
                            <ColumnLink className={'navigation-link'} url={pathCampuses}>
                                {schoolName}
                            </ColumnLink>
                        )
                    }
                    else {
                        return <span>{schoolName}</span>
                    }
                },
            },
            {
                title: fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryTeacherColumn }),
                dataIndex: SchoolVisitationHistoryPropsModel.visitationTeacherHistoriesCount,
                width: '15*',
                align: 'left' as 'left' | 'right' | 'center',
                className: 'navigation-col',
                render: (text, history, index) => {
                    const { regionId } = props.match.params;
                    const { visitationResponseModel: { schoolId, schoolName, visitationTeacherHistories }} = history;
                    if (visitationTeacherHistories) {
                        return (
                            <a className={'navigation-link'} onClick={()=>setModalIsOpenToTrue(visitationTeacherHistories)}>
                                {visitationTeacherHistories.length}
                            </a>
                        )
                    }
                    else {
                        return <span></span>
                    }
                },
            },
            {
                title: fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryTypeColumn }),
                dataIndex: SchoolVisitationHistoryPropsModel.visitationTypeText,
                width: '15*',
                render: (text, history, index) => {
                    const { visitationResponseModel: { type } } = history;
                    switch (type) {
                        case VisitationType.OnSite:
                            return <Icon type="environment" />;
                        case VisitationType.LVA:
                            return <Icon type="video-camera" />;
                        default:
                            return null;
                    }
                },
            },
            {
                title: fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryCampusColumn }),
                dataIndex: SchoolVisitationHistoryPropsModel.campusName,
                width: '20*',
                className: 'navigation-col',
                render: (text, history, index) => {
                    const { regionId } = props.match.params;
                    const { visitationResponseModel: { schoolId, campusId, campusName } } = history;
                    const pathClasses = regionId && schoolId && campusId ? GLUtil.pathStringify(PathConfig.Classes, { regionId, schoolId, campusId }) : null;
                    if (GLGlobal.isActionValid(GSSchoolAction.Campuses) && pathClasses) {
                        return (
                            <ColumnLink className={'navigation-link'} url={pathClasses}>
                                {campusName}
                            </ColumnLink>
                        )
                    }
                    else {
                        return <span>{campusName}</span>
                    }
                },
            },
            {
                title: fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryVisitDateColumn }),
                dataIndex: SchoolVisitationHistoryPropsModel.startDate,
                width: '15*',
                render: (text, history, index) => {
                    const { visitationResponseModel: { startDate } } = history;
                    return startDate ? DateHelper.formatDate2Local(startDate) : '';
                }
            }
        ];
        if (visitationUIStage == VisitationStage.OnGoing || visitationUIStage == VisitationStage.Completed) {
            columns.push({
                title: ' ',
                dataIndex: SchoolVisitationHistoryPropsModel.id,
                className: 'navigation-col',
                width: '10*',
                render: (text, history, index) => {
                    const { visitationResponseModel: { regionId, schoolId, id: visitationId } } = history ? history : {} as SchoolVisitationHistory;
                    const pathVisitationDetail = regionId && schoolId && visitationId ? GLUtil.pathStringify(SchoolPathConfig.CoachVisitation, { regionId, schoolId, visitationId }) : null;
                    return pathVisitationDetail ?
                        <ColumnLink className={'navigation-link'} url={pathVisitationDetail}>
                            {fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryViewDetailsText })}
                        </ColumnLink>
                        : null
                },
            });
        }
        return columns;
    }

    const getFeedbackColumns = (): ColumnProps<SchoolCoachFeedback>[] => {
        let columns = [
            {
                title: fmtMsg({ id: SchoolLocale.BreadTextTeacher }),
                dataIndex: SchoolCoachFeedbackModel.teacherName,
                width: '25*',
                className: 'navigation-col',
                render: (text, feedback, index) => {
                    const { regionId, schoolId, teacherId, teacherName } = feedback;
                    const teacherPath = GLUtil.pathStringify(PathConfig.SchoolTeacher, { regionId, schoolId, teacherId });
                    if (GLGlobal.isActionValid(GSSchoolAction.TeacherLink) && teacherPath) {
                        return (
                            <ColumnLink className={'navigation-link'} url={teacherPath}>
                                {teacherName}
                            </ColumnLink>
                        )
                    }
                    else {
                        return <span>{teacherName}</span>
                    }
                },
            },
            {
                title: fmtMsg({ id: SchoolLocale.SchoolVisitationHistorySchoolColumn }),
                dataIndex: SchoolCoachFeedbackModel.schoolName,
                width: '20*',
                render: (text, feedback, index) => {
                    const { regionId, schoolId, schoolName } = feedback;
                    const pathCampuses = regionId && schoolId ? GLUtil.pathStringify(PathConfig.Schools, { regionId, schoolId }) : null;
                    if (GLGlobal.isActionValid(GSSchoolAction.Campuses) && pathCampuses) {
                        return (
                            <ColumnLink className={'navigation-link'} url={pathCampuses}>
                                {schoolName}
                            </ColumnLink>
                        )
                    }
                    else {
                        return <span>{schoolName}</span>
                    }
                },
            },
            {
                title: fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryCampusColumn }),
                dataIndex: SchoolCoachFeedbackModel.campusName,
                width: '25*',
                className: 'navigation-col',
                render: (text, feedback, index) => {
                    const { regionId, schoolId, campusId, campusName } = feedback;
                    const pathClasses = regionId && schoolId && campusId ? GLUtil.pathStringify(PathConfig.Classes, { regionId, schoolId, campusId }) : null;
                    if (GLGlobal.isActionValid(GSSchoolAction.Campuses) && pathClasses) {
                        return (
                            <ColumnLink className={'navigation-link'} url={pathClasses}>
                                {campusName}
                            </ColumnLink>
                        )
                    }
                    else {
                        return <span>{campusName}</span>
                    }
                },
            },
            {
                title: fmtMsg({ id: SchoolLocale.SchoolCoachFeedbackReviewDate }),
                dataIndex: SchoolCoachFeedbackModel.reviewDate,
                width: '20*',
                render: (text, feedback, index) => {
                    const { reviewDate } = feedback;
                    return reviewDate ? DateHelper.formatDate2Local(reviewDate) : '';
                }
            },
            {
                title: "",
                width: '10*',
                render: (text, feedback, index) => {
                    return <a className="navigation-link" onClick={(e) => { e.preventDefault(); onViewFeedbackDetail(feedback); }}>
                        {fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryViewDetailsText })}
                    </a>;
                }
            }
        ];
        return columns;
    }

    const onStageChanged = (e) => {
        const newStage = e.target.value;
        setVisitationUIStage(newStage);
        getVisitation(newStage);
    }

    const onTrainingPathChanged = (e) => {
        setTrainingPathChanged(true);
    }

    const onCancel = (e) => {
        saveTrainingPath(e, false);
    }

    const onSaveTrainingPath = (e) => {
        saveTrainingPath(e);
    }

    const saveTrainingPath = (e, save = true) => {
        e.preventDefault();
        setTrainingPathChanged(false);
        maskThrottle();
        if (save) {
            trainingService.saveCourseTrainingInfo([{
                courseTypeId: TrainingCourseStatus.TrainingPath,
                completionDate: sliceMoment(props.form.getFieldValue(trainingPathField)),
                userId: coachId
            }]).then((result) => {
                unmaskThrottle();
            }).catch(er => {
                unmaskThrottle();
            });
        }
        else {
            props.form.resetFields();
            trainingService.getCourseTrainingInfo(coachId).then((result) => {
                formatTrainingPath(result[1].statuses);
                unmaskThrottle();
            }).catch(er => {
                unmaskThrottle();
            });
        }
    }

    const onViewFeedbackDetail = (feedback) => {
        maskThrottle();
        const { id, instanceId } = feedback;
        const params = {
            visitationId: id,
            surveyInstanceId: instanceId,
            type: SurveyTodoType.ReadOnlyShared
        }
        visitationService.viewSurveyReponse(params).then(data => {
            setReviewDetailVisible(true);
            setFeedbackDetail(feedback);
            setSurveyQuestion(data && data.surveyQuestion);
            unmaskThrottle();
        })
            .catch(er => {
                unmaskThrottle();
            });
    }

    const onViewCourseHistory = (page?: number) => {
        setCourseHistoryLoading(true);
        setCourseHistoryVisible(true);
        const limit = pagination.pageSize;
        let pageNumber = pagination.current;
        if (page) {
            pageNumber = page;
        }
        reportService.getCourseHistory(coachId, (pageNumber - 1) * limit, limit).then((result) => {
            setCourseHistories(result.data.map((c, index) => {
                return {
                    index,
                    itemId: c.item.id,
                    itemName: c.item.name,
                    courseVersionId: c.courseVersionModel ? c.courseVersionModel.id : null,
                    courseName: c.courseModel.name,
                    seriesId: c.seriesModel ? c.seriesModel.id : null,
                    seriesName: c.seriesModel ? c.seriesModel.name : '',
                    date: c.date,
                    type: c.type
                }
            }).sort((a, b) => -stringCompare(a.date, b.date, 'en')));
            setPagination({
                ...pagination,
                current: result.currentPage,
                total: result.totalRecords,
                pageSize: result.pageSize,
            });
            setCourseHistoryLoading(false);
        }).catch(er => {
            setCourseHistoryLoading(false);
        });
    }

    const onGetCourseQuestionnaire = (teacherId, questionnaireId, courseVersionId, seriesId) => {
        return trainingService.getCourseQuestionnaire(teacherId, questionnaireId, courseVersionId, seriesId);
    }

    const onCourseHistoryClose = () => {
        setCourseHistoryVisible(false);
        setPagination({
            ...pagination,
            current: 1,
            total: 0,
            pageSize: 20,
        });
        const { questionnaireId, courseVersionId } = GLUtil.queryParse();

        if (questionnaireId && courseVersionId) {
            // used window.history.replaceState instead of props.history.push or props.history.replace
            // because the latter causes many different api calls which is not required.
            window.history.replaceState(null, null, window.location.pathname);
        }
    }

    const renderContent = () => {
        const extendForm = { ...props.form, colon: true };
        const { coachAvatar, coachName, visitations, feedbacks } = coachVisitation;
        const showFeedbacks = GLGlobal.isActionValid(GSAdminAction.ViewCoachFeedback) && (feedbacks && feedbacks.length);
        return (
            <div className='content-layout school-coach'>
                <div className='page-content'>
                    <div className='school-coach-header'>
                        <UserAvatar
                            avatarSource={coachAvatar}
                            userName={coachName}
                        />
                        <GLForm layout='inline' className='school-coach-header-traning-path' form={props.form} onSubmit={onSaveTrainingPath}>
                            {FormHelper.renderFormItem(extendForm, GSAdminLocale.SchoolTrainerTrainingPathLabel, trainingPathField,
                                <DatePicker {...alignPop({ type: 'DatePicker' })}
                                    format={LanguageDateFormat[GLGlobal.intl.locale]}
                                    disabled={!GLGlobal.isActionValid(GSAdminAction.EditTrainingPath)}
                                    onChange={onTrainingPathChanged}
                                />, trainingPath)}
                            {trainingPathChanged &&
                                <IconButton
                                    onCancel={onCancel}
                                    onSave={onSaveTrainingPath}
                                />}
                        </GLForm>
                        <ReviewInfo
                            videoViewed={coachVisitation.videoViewed}
                            onViewCourseHistory={onViewCourseHistory}
                            classPrefix="school-coach"
                        />
                    </div>
                    <CourseHistory
                        visible={courseHistoryVisible}
                        teacherId={coachId}
                        histories={courseHistories}
                        classPrefix="school-coach"
                        getQuestionnaire={onGetCourseQuestionnaire}
                        onClose={onCourseHistoryClose}
                        pagination={pagination}
                        courseHistoryLoading={courseHistoryLoading}
                    />
                    <div className='school-coach-visitation'>
                        <div className='school-coach-visitation-title'>
                            <LabelText
                                labelId={GSAdminLocale.SchoolTrainerVisitationHistoryTitle}
                                hasColon={false}
                            />
                        </div>
                        <Stage
                            visitationStage={visitationUIStage}
                            onStageChanged={onStageChanged}
                        />
                        <div className='school-coach-visitation-history'>
                            <HistoryTable
                                columns={getColumns()}
                                histories={formatHistories(visitations)}
                            />
                        </div>
                    </div>
                    <div className={`school-coach-feedbacks ${showFeedbacks && "show"}`}>
                        <div className='school-coach-feedbacks-title'>
                            <LabelText
                                labelId={GSAdminLocale.SchoolTrainerFeedbackTitle}
                                hasColon={false}
                            />
                        </div>
                        <div className='school-coach-feedbacks-history'>
                            <FeedbackTable
                                highLightInfo={{ visitationId, teacherId }}
                                columns={getFeedbackColumns()}
                                feedbacks={feedbacks}
                            />
                        </div>
                    </div>
                    <ReviewFeedback
                        form={props.form}
                        feedback={feedbackDetail}
                        visible={reviewDetailVisible}
                        surveyQuestion={surveyQuestion}
                        onClose={() => setReviewDetailVisible(false)}
                    />
                    <TeacherModal
                    title={fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryTeacherTitleParticipated })}
                    visiable={visiblePopup}
                    visitationTeacher={visitationTeachers}
                    onCancel={setModalIsOpenToFalse}>
                    </TeacherModal>
                </div>
            </div>
        )
    }

    return renderContent();
}

export const SchoolCoachPage = withRouter(connect(null, { setBreadcrumbs })(GLForm.create()(SchoolCoach)));
