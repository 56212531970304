import React from 'react'
import { Icon } from 'antd-min';
import { SchoolLocale } from '@app/locales/localeid';
import { LabelText } from '@app/page/school/visitation/component/label-text';
import { VideoMessageModel, VideoViewedModel } from '@app/service/training';
import { HistoryIcon } from '@app/components/svgicon';
import { GLGlobal } from 'gl-commonui';
import { GSAdminAction, fmtMsg } from '@app/util';

interface ReviewInfoProps {
    videoViewed: VideoViewedModel
    onViewCourseHistory: () => void,
    classPrefix: string
}

export const ReviewInfo : React.FC<ReviewInfoProps> = ({videoViewed, onViewCourseHistory, classPrefix}) => {
    const formatTime = (time: string) => {
        const parts = time.split(':');
        return `${parts[0]}:${parts[1]}`;
    }

    const getVideoMessage = ({ courseName, seriesName, groupName, videoName }: VideoMessageModel) => {
        let message = `${courseName} >`;

        if (seriesName) {
            message = `${message} ${seriesName} >`;
        }
        if (groupName) {
            message = `${message} ${groupName} >`;
        }
        
        return `${message} ${videoName}`;
    }

    return (videoViewed ?
        <div className={`${classPrefix}-header-review-info`}>
            <LabelText
                labelId = {SchoolLocale.SchoolTeacherVideoReviewTitle}
                labelIdParams = {{
                    text: videoViewed.videoMessage ? getVideoMessage(videoViewed.videoMessage) : ''
                }}                
                hasColon = {false}
            />
            <div className={`${classPrefix}--header-review-info-review-count`}>
                <LabelText
                    labelId = {SchoolLocale.SchoolTeacherVideoReviewCountPart1}
                    labelIdParams = {{
                        totalVideos: videoViewed && videoViewed.totalVideos ? videoViewed.totalVideos : 0, 
                    }}
                    hasColon = {false}
                />
                <LabelText
                    labelId = {SchoolLocale.SchoolTeacherVideoReviewCountPart2}
                    labelIdParams = {{
                        lastMonthVideos: videoViewed && videoViewed.lastMonthVideos ? videoViewed.lastMonthVideos : 0, 
                    }}
                    hasColon = {false}
                />
            </div>
            <div className={`${classPrefix}-header-review-info-review-time`}>
                <LabelText
                    labelId = {SchoolLocale.SchoolTeacherVideoReviewTimePart1}
                    labelIdParams = {{
                        totalTime: videoViewed && videoViewed.totalTime ? formatTime(videoViewed.totalTime) : '', 
                    }}
                    hasColon = {false}
                />
                <LabelText
                    labelId = {SchoolLocale.SchoolTeacherVideoReviewTimePart2}
                    labelIdParams = {{
                        lastMonthTime: videoViewed && videoViewed.lastMonthTime ? formatTime(videoViewed.lastMonthTime) : '', 
                    }}
                    hasColon = {false}
                />
            </div>
            {GLGlobal.isActionValid(GSAdminAction.ListTrainingCourseHistory) && 
            <div className={`${classPrefix}-header-review-info-action`}>
                {/* <a className="school-teacher-header-review-info-action-button">
                    <Icon type="mail" />
                </a> */}
                <a 
                    title={fmtMsg({id:SchoolLocale.SchoolTeacherCourseHistoryTitle})}
                    className={`${classPrefix}-header-review-info-action-button`} 
                    onClick={(e) => {e.preventDefault(); onViewCourseHistory();}}
                >
                    <HistoryIcon />
                </a>
            </div>}
        </div> : null
    )
}