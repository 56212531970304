import React from 'react';
import './feedback-table.less';
import { Button, Row } from 'antd-min';
import { fmtMsg, DateHelper } from '@app/util';
import { CustomDrawer } from './custom-drawer';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { SurveyQuestions } from '../ongoing/component/survey-questions';
import { SchoolCoachFeedback } from '@app/service/coach/visitation';

interface ReviewFeedbackProps {
    visible: boolean
    surveyQuestion: any
    onClose: () => void
    form: WrappedFormUtils
    feedback: SchoolCoachFeedback
}

export const ReviewFeedback: React.FC<ReviewFeedbackProps> = ({visible, form, feedback, surveyQuestion, onClose}) => {
    const { reviewDate, teacherName } = feedback;
    return (
        <CustomDrawer 
            width={670}
            visible = {visible} 
            drawerTitle={
                <div className="feedback-info">
                    <Row><h3>{fmtMsg({id: SchoolLocale.BreadTextTeacher})}: {teacherName}</h3></Row>
                    <Row><h5>{fmtMsg({id: SchoolLocale.SchoolCoachFeedbackReviewDate })}: {reviewDate && DateHelper.formatDate2Local(reviewDate)}</h5></Row>
                </div>}
            className = 'school-coach-review-feedback-drawer'
            onClose = {() => onClose()}
        >
            <div className="send-feedback">
                <SurveyQuestions 
                    form={form}
                    isDisabled={true}
                    forceRender={!visible}
                    surveyQuestions={surveyQuestion}
                />
            </div>
            <div className='school-coach-review-feedback-drawer-close-button'>
                <Button onClick={() => onClose()} >
                    {fmtMsg({ id: GSAdminLocale.ButtonClose })}
                </Button>
            </div>
        </CustomDrawer>
    )
}