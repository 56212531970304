import { ColumnLink, WijmoGrid } from "@app/components/grid";
import { PathConfig, SchoolPathConfig } from "@app/config/pathconfig";
import { SchoolLocale } from "@app/locales/localeid";
import { Modal, Form} from 'antd-min';
import { SchoolVisitationHistory, SchoolVisitationHistoryPropsModel, VisitationTeacher } from "@app/service/coach/visitation";
import { CampusModel } from "@app/service/schools";
import { SubmitBtns } from '@app/components';
import { DateHelper, fmtMsg, GSAdminAction } from "@app/util";
import { GLGlobal, GLUtil, withRouter } from "gl-commonui";
import React, { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import "./history-table.less";
import { LabelText } from "./label-text";

const { Grid, Column } = WijmoGrid;

interface HistoryTableProps extends Partial<RouteComponentProps> {
    header?: JSX.Element;
    campus?: CampusModel;
    campusHistories?: SchoolVisitationHistory[];
}

export const HistoryTable: React.FC<HistoryTableProps> = withRouter((props: HistoryTableProps) => {
    const { header, campus, campusHistories } = props;
    const [visiblePopup, setVisiblePopup] = useState<boolean>(false);
    const [visitationTeachers, setVisitationTeachers] = useState<VisitationTeacher[]>();
    const setModalIsOpenToTrue = (listVisition: VisitationTeacher[]) => {
        setVisitationTeachers(listVisition);
        setVisiblePopup(true);
      };
    const setModalIsOpenToFalse =()=>{
        setVisiblePopup(false)
    }
    const getHeader = (campus: CampusModel) => {
        const { regionId, schoolId, id: campusId } = campus ? campus : ({} as CampusModel);
        const pathClasses = regionId && schoolId && campusId ? GLUtil.pathStringify(PathConfig.Classes, { regionId, schoolId, campusId }) : null;
        return header ? (
            header
        ) : campus ? (
            <div className="history-header">
                <LabelText labelId={SchoolLocale.SchoolVisitationHistoryCampusTitleLabel}>
                    {pathClasses ? (
                        <Link className={"navigation-link"} to={pathClasses}>
                            {campus.name}
                        </Link>
                    ) : (
                        campus.name
                    )}
                </LabelText>
            </div>
        ) : null;
    };
    const formatData = (items: SchoolVisitationHistory[]) => {
        return items.map((item) => ({
            ...item,
            teacherCount: item.visitationTeacherHistories.length,
        }))
    }

    return (
        <Grid
            itemsSource={formatData(campusHistories)}
            pagination={false}
            title={getHeader(campus)}
            loadedRows={(s) => {
                s.rows.forEach((row) => {
                    if (!row.dataItem.completedDate) {
                        row.cssClass = "ongoing-row";
                    }
                });
            }}
            className="history-table"
        >
            <Column
                header={fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryCoachColumn })}
                binding={SchoolVisitationHistoryPropsModel.coachName}
                render={(text, history) => {
                    const { regionId, schoolId, coachId: trainerId } = history ? history : ({} as SchoolVisitationHistory);
                    const pathSchoolCoach =
                        regionId && schoolId && trainerId ? GLUtil.pathStringify(PathConfig.SchoolTrainer, { regionId, schoolId, trainerId }) : null;
                    return pathSchoolCoach && GLGlobal.isActionValid(GSAdminAction.SchoolTrainerHome) ? (
                        <ColumnLink className="navigation-link" history={props.history} url={pathSchoolCoach}>
                            {text}
                        </ColumnLink>
                    ) : (
                        text
                    );
                }}
            />
             <Column
                header={fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryTeacherColumn })}
                binding={SchoolVisitationHistoryPropsModel.teacherCount}
                align={"left"}
                render={(text: number, history) => {
                    if (text) {
                        const teachers = history[SchoolVisitationHistoryPropsModel.visitationTeacherHistories];
                        return (
                            <a className="navigation-link" onClick={() => setModalIsOpenToTrue(teachers)} >
                                {text}
                            </a>
                        )
                    } else {
                        return <span></span>
                    }
                }}
            />
            <Column
                header={fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryVisitDateColumn })}
                binding={SchoolVisitationHistoryPropsModel.startDate}
                render={(text, history) => {
                    return history.startDate ? DateHelper.formatDate2Local(history.startDate) : null;
                }}
            />
            <Column
                header={fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryCompleteDateColumn })}
                binding={SchoolVisitationHistoryPropsModel.completedDate}
                render={(text, history) => {
                    return history.completedDate ? DateHelper.formatDate2Local(history.completedDate) : null;
                }}
            />
            <Column
                render={(text, history) => {
                    const { regionId, schoolId, id: visitationId } = history ? history : ({} as SchoolVisitationHistory);
                    const pathVisitationDetail =
                        regionId && schoolId && visitationId
                            ? GLUtil.pathStringify(SchoolPathConfig.CoachVisitation, { regionId, schoolId, visitationId })
                            : null;
                    return pathVisitationDetail ? (
                        <ColumnLink className="navigation-link" history={props.history} url={pathVisitationDetail}>
                            {fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryViewDetailsText })}
                        </ColumnLink>
                    ) : null;
                }}
            />
            <TeacherModal
            title={fmtMsg({ id: SchoolLocale.SchoolVisitationHistoryTeacherTitleParticipated })}
            visiable={visiblePopup}
            visitationTeacher={visitationTeachers}
            onCancel={setModalIsOpenToFalse}>
            </TeacherModal>
        </Grid>
    );
});
export interface ITeacherModalProps {
    title: string;
    visiable: boolean;
    visitationTeacher: VisitationTeacher[],
    onCancel?: () => void
}

export const TeacherModal: React.FunctionComponent<ITeacherModalProps> = props => {
    const{visiable , title, onCancel}= props;
    return (
        <Modal
        className={'product-modal modal-visitor'}
        closable={false}
        footer={[<SubmitBtns hideSubmitButton={true} onCancel={onCancel} />]}
        destroyOnClose={true}
        title={title}
        visible={visiable} >
            <ul>
            {props.visitationTeacher != null ? props.visitationTeacher.map(listTeacher => (
                <li><ColumnLink key={listTeacher.teacherId} className="navigation-link" url={listTeacher.teacherLink}>
                            {listTeacher.teacherName}
                        </ColumnLink></li>))
                : null}
            </ul>
        </Modal>
    );
};
