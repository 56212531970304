import React from 'react';
import classNames from 'classnames';
import { ColumnProps } from "antd/lib/table";
import './feedback-table.less';
import { WijmoGrid } from '@app/components';

const { Grid, Column } = WijmoGrid;

interface FeedbackTableProps {
    columns: ColumnProps<any>[]
    rowKey?: string
    feedbacks?: any[],
    highLightInfo?: highLightModel,
    scroll?: {
        x?: boolean | number | string;
        y?: boolean | number | string;
    }
}

interface highLightModel {
    teacherId: string,
    visitationId: string,
}

export const FeedbackTable: React.FC<FeedbackTableProps> = ({columns, rowKey, feedbacks, highLightInfo, scroll}) => {

    const feedbackTableClassName = {
        'feedback-table': true,
        'have-data': feedbacks && feedbacks.length > 0,
        'no-data': !feedbacks || feedbacks.length == 0
    }
    return (
        <Grid
            className={classNames(feedbackTableClassName)}
            loadedRows={(flex) => {
                flex.rows.forEach((row) => {
                    if (row.dataItem.id == highLightInfo.visitationId && row.dataItem.teacherId === highLightInfo.teacherId) {
                        row.cssClass = "highlight-row";
                    }
                });
            }}
            itemsSource={feedbacks}
            pagination={false}
        >
            {
                columns.map(column => {
                    return <Column
                        key={column.dataIndex}
                        header={column.title.toString()}
                        binding={column.dataIndex}
                        width={column.width}
                        cssClass={column.className}
                        align={column.align}
                        render={column.render ? (value, item, context) => column.render(value, item, context.row.index) : undefined}
                    />
                })
            }
        </Grid>
    )
}
