import React from 'react';
import { Card, Row, Col, Divider, Switch, Checkbox, Radio } from 'antd-min';
import { QuestionnaireModel, QuestionModel, AnswerModel, CourseHistoryModel } from '@app/service/training';
import { TrainingQuestionType, fmtMsg } from '@app/util';
import { TrainingLocale } from '@app/locales/localeid';
import { LabelText } from '@app/page/school/visitation/component/label-text';

interface CourseQuestionnaireProps {
    questionnaire: QuestionnaireModel
    courseHistory: CourseHistoryModel
    classPrefix: string;
}

export const CourseQuestionnaire: React.FC<CourseQuestionnaireProps> = ({questionnaire, courseHistory, classPrefix}) => {

    const classNamePrefix = `${classPrefix}-course-history-drawer-questionnaire`;

    const renderAnswer = (question: QuestionModel, answer: AnswerModel) => {
        const { answer: answerText, isCorrect, isSelected } = answer;
        let answerTemplate = <div></div>;
        let answerClassName = '';
        switch (question.questionTypeId) {
            case TrainingQuestionType.MultiChoice:
                answerTemplate = <Checkbox disabled checked={isSelected} />;
                answerClassName = ' check-box';
                break;
            case TrainingQuestionType.Option:
                answerTemplate = <Radio disabled checked={isSelected} />;
                answerClassName = 'radio-box';
                break;
            default:
                answerTemplate = <></>;
                answerClassName = 'text-box';
        }
        const renderTextArea = (answerText) => {
            if (answerText != null && answerText.trim() != '') {
                return <pre>{answerText.trim()}</pre>;
            }
            else {
                return <pre><span> </span></pre>
            }
        }
        return (
            <div className={`${classNamePrefix}-question-item-answer-item`} key={answer.id}>
                <div className={`${classNamePrefix}-question-item-answer-item-content ${isCorrect && isSelected ? 'answer-correct' : 'answer-incorrect'}`}>
                    {answerTemplate}
                    <div className={`${classNamePrefix}-question-item-answer-item-t ${answerClassName}`}>
                        {question.questionTypeId == TrainingQuestionType.Text &&
                        renderTextArea(answerText)
                        }
                        {question.questionTypeId != TrainingQuestionType.Text &&
                        <div className={`${classNamePrefix}-question-item-answer-item-text`}>{answerText}</div>
                        }                        
                    </div>
                </div>
            </div>
        );
    }

    const renderAnswers = (question: QuestionModel) => (
        <div className={`${classNamePrefix}-question-item-a`}>
            {[...question.answers].sort((a1, a2) => a1.order - a2.order).map(answer => renderAnswer(question, answer))}
        </div>
    );

    const renderQuestion = (question, index) => (
        <div className={`${classNamePrefix}-question-item`} key={question.id}>
            <div className={`${classNamePrefix}-question-item-q`}>
                <div className={`${classNamePrefix}-question-item-q-sr`}>Q.{index + 1}</div>
                <div className={`${classNamePrefix}-question-item-q-text`}>{question.question}</div>
            </div>
            {renderAnswers(question)}
        </div>
    );

    const renderQuestions = (questions: QuestionModel[]) => (
        <div className={`${classNamePrefix}-list`}>
            <div className={`${classNamePrefix}-list-container`}>
                {[...questions]
                    .sort((q1, q2) => q1.order - q2.order)
                    .map((question: QuestionModel, index: number) => (
                        <div className={`${classNamePrefix}-list-item`} key={question.id}>
                            {renderQuestion(question, index)}
                        </div>
                    ))}
            </div>
        </div>
    );

    return (
        <Card className={classNamePrefix}>
            <div className="ant-card-body">
                <Row type="flex">
                    <LabelText
                        labelId = {TrainingLocale.QuestionnaireLabel}
                        text = {courseHistory.itemName}
                    />
                </Row>                
                <Row type="flex">
                    <LabelText
                        labelId = {TrainingLocale.QuestionnaireCourseLabel}
                        text = {courseHistory.courseName}
                    />
                    <LabelText
                        labelId = {TrainingLocale.QuestionnaireSeriesLabel}
                        text = {courseHistory.seriesName}
                    />
                </Row>
            </div>
            <Divider className={`${classNamePrefix}-card-divider`} />
            <div className="ant-card-body">
                <h3>{fmtMsg({ id: TrainingLocale.QuestionnaireQuestionLabel })}</h3>
                {renderQuestions(questionnaire.questions)}
            </div>
        </Card>
    )    
}