import React from 'react';
import { Avatar } from 'antd-min';
import './avatar.less';

export const UserAvatar = ({ avatarSource, userName }) => {
    return (
        <div className='school-user-header-avatar'>
            <Avatar 
                className='school-user-header-avatar-cover'
                shape='circle'
                icon='user'
                src={avatarSource}
            />
            <div className='school-user-header-avatar-title'>
                <span>{userName}</span>
            </div>
        </div>
    )

}