import React from 'react';
import { Radio } from 'antd-min';
import { VisitationStage } from '@app/util/coach/enum';
import { GSAdminLocale } from '@app/locales/localeid';
import { fmtMsg } from '@app/util';

export const Stage = ({ visitationStage, onStageChanged }) => {

    return (
        <div className='school-coach-visitation-stage'>
            <Radio.Group value={visitationStage} defaultValue={VisitationStage.OnGoing} onChange={onStageChanged} >
                <Radio className="ongoing" value={VisitationStage.OnGoing}>{fmtMsg({id: GSAdminLocale.SchoolTrainerStageOnGoing})}</Radio>
                <Radio className="pending" value={VisitationStage.Pending}>{fmtMsg({id: GSAdminLocale.SchoolTrainerStagePending})}</Radio>
                <Radio className="completed" value={VisitationStage.Completed}>{fmtMsg({id: GSAdminLocale.SchoolTrainerStageComplete})}</Radio>
            </Radio.Group>
        </div>
    )
    
}