import React, { useState, useEffect } from 'react';
import { GLUtil } from 'gl-commonui';
import { Button, Spin } from 'antd-min';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { QuestionnaireModel, CourseHistoryModel, QuestionModel } from '@app/service/training';
import { fmtMsg } from '@app/util';
import { CustomDrawer } from '../../component/custom-drawer';
import { CourseHistoryTable } from './course-history-table';
import { CourseQuestionnaire } from './course-questionnaire';

interface QuestionnaireBackend {
    questionnaireModel: { id: string, name: string }
    questions: any[]
}

interface CourseHistoryProps {
    visible: boolean;
    courseHistoryLoading: boolean;
    teacherId: string;
    histories: CourseHistoryModel[];
    pagination: {
        current: number;
        total: number;
        hideOnSinglePage: boolean;
        pageSize: number;
        onChange: (page) => void;
    };
    classPrefix: string;
    getQuestionnaire: (teacherId, questionnaireId, courseVersionId, seriesId) => QuestionnaireBackend;
    onClose: () => void;
}

export const CourseHistory: React.FC<CourseHistoryProps> = ({ visible, teacherId, histories, pagination, classPrefix, getQuestionnaire, onClose, courseHistoryLoading }) => {

    useEffect(() => {
        const queryParams = GLUtil.queryParse();

        if (queryParams && queryParams.questionnaireId && queryParams.courseVersionId
            && histories && histories.length) {

            let { questionnaireId, courseVersionId, seriesId } = queryParams;
            seriesId = seriesId || null;

            const questionnaireHistory = histories.find(history => history.itemId === questionnaireId
                && history.courseVersionId === courseVersionId
                && history.seriesId === seriesId);

            if (questionnaireHistory) {
                onViewCourseQuestionnaire(questionnaireHistory);
            }
        }
    }, [histories]);

    const [courseTableVisible, setCourseTableVisible] = useState(true);
    const [loadingCourseDetail, setLoadingCourseDetail] = useState(false);
    const [currentCourseHistory, setCurrentCourseHistory] = useState({} as CourseHistoryModel);
    const [questionnaire, setQuestionnaire] = useState({} as QuestionnaireModel);

    const onViewCourseQuestionnaire = async (history: CourseHistoryModel) => {
        setLoadingCourseDetail(true);
        const result = await getQuestionnaire(teacherId, history.itemId, history.courseVersionId, history.seriesId);
        setCurrentCourseHistory(history);
        setQuestionnaire({
            id: result.questionnaireModel.id,
            name: result.questionnaireModel.name,
            questions: result.questions.map((q) => {
                return {
                    id: q.id,
                    question: q.question,
                    questionTypeId: q.questionTypeId,
                    answers: q.options ? q.options.map((o) => {
                        return {
                            id: o.id,
                            answer: o.answer1,
                            isCorrect: o.isCorrect,
                            isSelected: q.answer && q.answer.answers && q.answer.answers.filter(a => a.id == o.id).length > 0 ? true : false,
                            order: o.order,
                        }
                    }) : [{
                        id: q.id,
                        answer: q.answer ? q.answer.textAnswer : '',
                        isCorrect: true,
                        isSelected: true,
                        order: 1,
                    }],
                    order: q.order || 1
                }
            })
        } as QuestionnaireModel);
        setCourseTableVisible(false);
        setLoadingCourseDetail(false);
    }

    return (
        <CustomDrawer
            visible={visible}
            drawerTitle={fmtMsg({ id: SchoolLocale.SchoolTeacherCourseHistoryTitle })}
            className={`${classPrefix}-course-history-drawer`}
            width={700}
            onClose={() => { setCourseTableVisible(true); onClose(); }}
        >
            <Spin spinning={loadingCourseDetail || courseHistoryLoading}>
                {courseTableVisible &&
                    <CourseHistoryTable
                        histories={histories}
                        pagination={pagination}
                        onViewCourseQuestionnaire={onViewCourseQuestionnaire}
                        classPrefix={classPrefix}
                    />}
                {!courseTableVisible &&
                    <CourseQuestionnaire
                        questionnaire={questionnaire}
                        courseHistory={currentCourseHistory}
                        classPrefix={classPrefix}
                    />
                }
                <div className={`${classPrefix}-course-history-drawer-close-button`}>
                    <Button onClick={() => {
                        courseTableVisible && onClose();
                        setCourseTableVisible(true);
                    }} >
                        {courseTableVisible ?
                            fmtMsg({ id: GSAdminLocale.ButtonClose }) :
                            fmtMsg({ id: GSAdminLocale.InvitationModalBack })
                        }
                    </Button>
                </div>
            </Spin>
        </CustomDrawer>
    )
}

CourseHistory.defaultProps = {
    courseHistoryLoading: false
};