import React from 'react';
import classNames from 'classnames';
import { ColumnProps } from "antd/lib/table";
import './history-table.less';
import { WijmoGrid } from '@app/components';

const { Grid, Column } = WijmoGrid;

interface HistoryTableProps {
    columns: ColumnProps<any>[]
    rowKey?: string
    histories?: any[],
    scroll?: {
        x?: boolean | number | string;
        y?: boolean | number | string;
    }
}

export const HistoryTable: React.FC<HistoryTableProps> = ({columns, rowKey, histories, scroll}) => {

    const historyTableClassName = {
        'history-table': true,
        'have-data': histories && histories.length > 0,
        'no-data': !histories || histories.length == 0
    }

    return (
        <Grid
            className={classNames(historyTableClassName)}
            itemsSource={histories}
            pagination={false}
        >
            {
                columns.map(column => {
                    return <Column
                        key={column.dataIndex}
                        header={column.title.toString()}
                        binding={column.dataIndex}
                        width={column.width}
                        cssClass={column.className}
                        align={column.align}
                        render={column.render ? (value, item, context) => column.render(value, item, context.row.index) : undefined}
                    />
                })
            }
        </Grid>
    )

}
