import React from 'react';
import classNames from 'classnames';
import { Icon } from 'antd-min';
import { SchoolLocale } from '@app/locales/localeid';
import { CourseHistoryModel, CourseHistoryPropsModel } from '@app/service/training';
import { fmtMsg, DateHelper, TrainingCourseType } from '@app/util';
import { WijmoGrid } from '@app/components';
const { Grid, Column } = WijmoGrid;

interface CourseHistoryTableProps {
    histories: CourseHistoryModel[],
    pagination: {
        current: number;
        total: number;
        hideOnSinglePage: boolean;
        pageSize: number;
        onChange: (page) => void;
    },
    onViewCourseQuestionnaire: (history: CourseHistoryModel) => void
    classPrefix: string;
}

export const CourseHistoryTable: React.FC<CourseHistoryTableProps> = ({histories, pagination, classPrefix, onViewCourseQuestionnaire}) => {
    const getColumns = () => {
        return [
            {
                title: fmtMsg({id:SchoolLocale.SchoolTeacherCourseHistoryItemColumn}),
                dataIndex: CourseHistoryPropsModel.itemName,
                width: "*",
                maxWidth: 260,
                render: (text, history, index) => {
                    return (
                        <div className={`${classPrefix}-course-history-drawer-table-col-itemname`}>
                            { history.type == TrainingCourseType.Video && 
                            <div className={`${classPrefix}-course-history-drawer-table-col-itemname-video`}>
                                <Icon type='video-camera' /> 
                                {/* <VideoIcon /> */}
                                <span> {text}</span>
                            </div>
                            }
                            { history.type == TrainingCourseType.Questionnaire && 
                            <div className={`${classPrefix}-course-history-drawer-table-col-itemname-question`}>
                                <Icon type='question-circle' /> 
                                {/* <QuestionnaireIcon /> */}
                                <a onClick={(e) => {e.preventDefault(); onViewCourseQuestionnaire(history)}}>
                                    {text}
                                </a>
                            </div>
                            }
                            {
                              history.type === TrainingCourseType.Link &&
                                <div className={`${classPrefix}-course-history-drawer-table-col-itemname-link`}>
                                    <Icon type='link' /> 
                                    <span> {text} </span>
                                </div>
                            }
                        </div>
                    );
                },
            },
            {
                title: fmtMsg({id:SchoolLocale.SchoolTeacherCourseHistoryCourseColumn}),
                dataIndex: CourseHistoryPropsModel.courseName,
                width: "1.5*",
                maxWidth: 165
            },
            {
                title: fmtMsg({id:SchoolLocale.SchoolTeacherCourseHistorySeriesColumn}),
                dataIndex: CourseHistoryPropsModel.seriesName,
                width: "1.5*",
                maxWidth: 160
            },
            {
                title: fmtMsg({id:SchoolLocale.SchoolTeacherCourseHistoryDateColumn}),
                dataIndex: CourseHistoryPropsModel.date,
                width: "1*",
                maxWidth: 85,
                render: (text, history, index) => {
                    return history.date ? DateHelper.formatDate2Local(history.date) : '';
                }
            }
        ];
    }

    const courseHistoryTableClassName = {
        [`${classPrefix}-course-history-drawer-table`]: true,
        'have-data': histories && histories.length > 0,
        'no-data': !histories || histories.length == 0
    }

    return (
        <Grid 
            itemsSource={histories}
            paginationSize={pagination.pageSize}
            paginationTotal={pagination.total}
            className= {classNames(courseHistoryTableClassName)}
            autoRowHeights={true}
            stickyHeaders={false}
            onPageSelected={page => pagination.onChange(page)}
        >
            {
                getColumns().map(column => {
                    return <Column
                                header={column.title}
                                binding={column.dataIndex}
                                render={column.render}
                                width={column.width}
                                maxWidth={column.maxWidth}
                                minWidth={165}
                                wordWrap={true}
                            />
                })
            }
        </Grid>
    )

}